<template>
  <el-dialog
    title="创建子用户结算户"
    :visible="addDialogVisible"
    width="600px"
    @close="closeDialog"
  >
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="80px"
    >
      <el-form-item multi style="width: 100%" prop="user" label="角色">
        <el-select
          v-model="ruleForm.user"
          placeholder="请选择用户"
          style="width: 100%"
        >
          <el-option
            v-for="item in userList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算中心权限" prop="platforms">
        <el-checkbox-group v-model="ruleForm.platforms">
          <el-checkbox
            v-for="item in platformList"
            :key="item.pay_platform"
            :label="item.shortname"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
// import moment from "moment";
import { api } from '../../api';
// import * as _ from "lodash";
export const userDescribeSubuser = api('/subuser')(
  'user.describe.subuser.json'
);
export const FinancialSubAccountAdd = api('/financialSubAccount')(
  'financialSubAccount.add.json'
);

export default {
  data() {
    return {
      userList: [],
      platformList: [],
      ruleForm: {
        user: '',
        platforms: []
      },
      rules: {
        user: [{ required: true, message: '请选择用户', trigger: 'change' }],
        platforms: [
          { required: true, message: '请选择结算中心权限', trigger: 'change' }
        ]
      }
    };
  },
  props: {
    addDialogVisible: Boolean
  },
  created() {
    this.platformList = this.$route.params.$preload.platformList;
    this.getList();
  },
  methods: {
    async getList() {
      const res = await userDescribeSubuser({
        pageSize: 1000,
        pageNumber: 1,
        roleName: '5,8'
      });
      this.userList = res.list;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const _platforms = this.ruleForm.platforms
            .map(it => this.platformList.find(_it => _it.platform_name === it))
            .map(it => it.pay_platform)
            .join(',');
          await FinancialSubAccountAdd({
            enterpriseAccount: window.localStorage.getItem('enterprise'),
            payPlatform: _platforms,
            subUserAccount: this.ruleForm.user
          });
          this.$message.success('创建成功');
          this.closeDialog();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog() {
      this.$emit('update:addDialogVisible', false);
      this.$emit('addClose');
    }
  }
};
</script>

<style>
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
